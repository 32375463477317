.Button_border{
    border: 1px solid rgb(207, 207, 207);
}
 #my{
     /* width: 1000px !important;   
    max-width: 1000px !important;  */
    min-width: 640px !important;
    
} 


.radios{
    border-radius: 25px;

}
.color{
    color: rgb(64, 226, 43);
}
#inputH {
    min-height: 5px !important;
    height: 45px !important;
   
}

@media screen and (max-width:650px){
    #my{
        min-width: 100vw !important;  
    }
    }