
    @media (min-width: 768px) {
        .newestHome{
        margin-top: 120px;
        }
      }
      /* @media (min-width: 1024px) {
        .newestHome{
        margin-top: 150px;
        }
      } */
