.hero-section{
    height: 19.625rem;
}
.bg-want{
    background-color: #e6ebf3;
}
.border-radius-10{
    border-radius: 10px;
}
.description{
    padding: 100px 0;
}
.custom-select-height{
    height: 58px;
  
}
/* .custom-select-height:hover{
    color: #043683;
} */

.custom-select-height:focus{
 background-color: #f3f0f0 !important;  
 color: #043683; 
}


.hero-section .description div{
    width: 50%;
    margin: auto;
}
.text-color{
    color:#043683
}
.my-Container{
width: 980px;
margin: auto;
}
.Child-1{
width: 28%;

}
.Child-2{
    width: 67%;

}
.fs-11{
    font-size: 0.75rem;
}
.w-30{
    width: 260px;
    padding:0.4rem;
}
.my-fw-bold{
font-weight: 500;
}
.border-radius-20{
    border-radius: 20px !important;
}
.fs-9{
    font-size: 10px !important;
}
.my-Container .Child-1 a:hover{
    color: #043683 !important;
}
.i-hover:hover{
    color : #043683 !important;
}
.br{
   border: 1px solid grey;
}
.p-2-3{
    padding: 2.3rem;
}
.fit-cover{
    object-fit: cover;
}
.my-float-right{
    float: right !important;
}
.text-right{
    text-align: right;
}
.my-ms-5{
    margin-left: 3rem !important;
}
.dis-flex{
    display: flex;
}
.text-orange{
    color: #ee6c4dff;
}

.collapser:hover{
    color: #043683 !important;
}
.form .input{
    height: 40px !important;
    
    
}
.p-0{
    padding: 0 !important;
    outline: none !important;
}   


.input:hover{
    border: 1px solid black !important;
}
    label{
        left: 1rem;
        right: auto;
        top: 50%;
        width: calc(100% - 0.5rem);

    }
    .my-float-right{
        float: right;
    }

#last{
    font-size: 10px;
    font-weight: bold;
}
@media screen and (min-width:1024px) {
    .hidden-lg{
        display: none;
    }
}
@media screen and (max-width:1024px) {
    .hidden-md{
        display: none;
    }
    .mb-auto-md{
        margin-bottom: auto !important;
    }
    .my-float-right{
        float: none !important;
    }
    .president{
height: 164px;
        width: 164px;
    }
    .blocking{
        display: block !important;
    }
 .hero-section .description div{
        width: 95%;
    }
    .my-Container{
        width: 100%;
        margin: auto;
    }
    .dis-flex{
        display: block !important;   
    }
    .br{
        border: none !important;
    }
    .Child-1{
        padding: 1rem;
        width: 100%;
    }
    .Child-2{
        padding: 1rem;
        width: 100%;

    }
   
}
@media screen and (max-width:420px) {
    /* .members{
        width: 140px;
        height: 140px;
    } */
    .p-2-3{
        padding: 0.3rem;

    }
    .details{
        margin-left: .5rem !important;

    }
    
}
@media screen and (max-width:767px) {
    .unique{
        width: 100%;
    }   
    
}
@media screen and (max-width:450px) {
    .unique div div img{
        width: 144px;
        height: 144px;
    }
    .my-ms-5{
        margin-left: 1rem !important;
    }
}

