:root{
  --text-color: #BF1017 !important;
}
/* :root{
--bg-color: #f4b983 !important;
} */
.theme-color{
border: 1px solid var(--text-color);
}
.text-colour{
  color: var(--text-color);
}
.btn-bg{
background-color: var(--text-color);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.bg-img{
  background-image: url(./img/homePage.jpg);
  background-size: cover;
}
@media (min-width: 768px) {
  .bg-img{
  height: 500px;
  background-size:cover;

  }
}
/* @media (min-width: 1024px) {
  .bg-img{
    background-size: 100% 100%;
  }
  .bg-img{
    height: 550px;
    }
} */











/* .bg-img{
  background-image: url(./img/homePage.jpg);
  background-size: 100% 100%;
}
@media (min-width: 768px) {
  .bg-img{
  height: 600px;
  }
}
@media (min-width: 1024px) {
  .bg-img{
  height: 600px;
  }
} */

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(#BF1017 75%, gray 0);    
}

/* .progress-bar::before {
  content: "75%";
} */