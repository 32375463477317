.fn-style{
    font-weight: 500;
    font-size: 16px;
  }
  .Search-div:hover{
  border:blue;
  }
  .br{
    border: 1px solid rgb(0, 0, 0);
  }
  .table{
    background-color: burlywood;
  }
  
  .a-i-center{
    align-items: center !important;
  }
  .my-container{
    width: 95%;
    margin: auto;
  }
  .p-0{
    padding: 0 !important;
  }
  .active-listing-card{
    background-color: #e6ebf3;
  }
  .active-listing-card > a{
    text-decoration: none;
  }
  
  
  .table-head{
    background-color:black;
  }
  .font-fly{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  } 
  .sales-catagory-card{
  width:22%;
  padding: 4px;
  }
  .progress-number{
    padding:2px;
    font-size: 10px;
   
  }
  
  .lock-button{
    z-index: 5;
  }
  .value-incease{
    background-color: rgba(120, 236, 105, 0.20);
    color: rgb(43, 114, 43);
  }
  .value-decrease{
    background-color: rgba(228, 121, 22, 0.2);
    color: rgb(247, 33, 5);
  }
  .price-box tr td{
    filter: blur(5px);
  }
  .p-0{
    padding: 0 !important;
  }
  .tips-heading{
    font-size: 19px;
    font-weight: 900;
  }
  .tips-details{
    font-size: 14px;
    font-weight: 700;
    word-spacing: 2px;
  }
  @media (max-width: 400px) {
    .logo-img{
      width:55px
    }
    .citySearch{
      display: none;
    }
  }
  @media (max-width: 768px) {
    .sales-catagory-card{
      width:45%;
    }
    .learest-city-list{
      font-size: 10px;
  
    }
  
  }
  @media (max-width: 410px) {
    .open-search{
    display: none;
    }
  
  }
  @media (min-width: 410px) {
    .toggle-search{
    display: none;
    }
  
  }
  
  
  
  @media (max-width: 400px) {
    .sales-catagory-card{
      width:95%;
    }
  
    .learest-city-list{
      font-size: 6px;
  
    }
  
  }
  .blur-box{
    filter: blur(5px);
  }
  #pricing-table{
    padding: 2% !important;
  }
  @media (max-width: 550px) {
  #pricing-table{width: 100% !important;
  margin-top: 3%;
  
  
  } 
  }
  