/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.widthP{
  width: fit-content !important;
  max-width: 800px !important;
  border-radius: 25px;
}

.widthListP{
  width: fit-content !important;
  max-width: 800px !important;
  border-radius: 12px;
}
.primery{
  color: #0561EB !important;
}
.bgPrimery{
  background-color: #0561EB !important;
}
.bgPrimeryDark{
  background-color: #021B42;
}
.borderPrimery{
  border: solid 1px #0561EB !important;
}
.bgLightGray{
  background-color: rgb(233, 233, 233);
}

.borderPrimeryH :hover{
  border: solid 2px #0561EB;
}

.textBlack{
  color: #212121;
}

#spaceSortTooltip{
  padding: 0;
}


.primeryH:hover{
  color: #0561EB;
}



#spaceSortTooltip{
  padding: 0px;
}
.popover-body{
  padding: 5px !important;
}


.gm-style .gm-style-iw-c {
  padding: 0px !important;
   
   background-color: transparent;
   border-radius: 8px;
   box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
   
}


.gm-style-iw-d{
  
  overflow: hidden !important;
}
.gm-style-iw-tc{
 display: none;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}




.gm-ui-hover-effect{
display: none !important;
}

.zIndexClass{
  z-index: 10000 !important;
}

.productDetailBorder{
  border: #134ead solid 1px;
}