.input-border:focus {
    outline: none !important;
  }
  .t-border{
    border-top: 1px solid rgb(226, 226, 226);
    /* border-bottom: 1px solid rgb(226, 226, 226); */
  }

.border-gray{
    border : 0.5px solid rgb(238, 238, 238);
}


  .box-shadow{
    box-shadow: 0px 10px 25px -6px rgba(0,0,0,0.1);
  }

  @media (min-width: 768px) {
    .Search-Box{
      margin-top: 50px;
    }
  }
  /* @media (min-width: 1024px) {
    .Search-Box{
      margin-top: 70px;
    }
  } */
 
  /* .Search-Box{
    margin-top: 100px;
  } */