
.information{
    width: 50%;
    margin: auto; 
    padding: 0px 1rem;
}
.heading{
    color: rgb(35,69,133);
}
.text-color{
    color: rgb(101,101,101);
}
.search{
    width: 50%;
    border: 1px solid gray;
}

@media screen and (max-width:1440px) {
    .information{
        width: 50%;
    }
    .search{
        width: 90%;
    }
} 

@media screen and (max-width:1024px) {
    .information{
        width: 90%;
    }
    .search{
        width: 70%;
    }
}

@media screen and (max-width:768px) {
    .search{
        width: 80%;
    }
}

@media screen and (max-width:426px) {
    .search{
        width: 100%;
    }
    .img{
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
}
    